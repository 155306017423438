import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { RestService } from './rest.service';
import {
  Autocomplete,
  GooglePlace,
  Locale,
  RoleType,
  User,
  UserParameter,
  UserPhotoData
} from '../../models';
import { FileService } from './file.service';
import { StorageService } from '../global';
import { LocalizationService } from '../localization';
import { NotificationService } from '../helpers';
import { NotificationTypeEnum } from '../../enums';
import { RoutesHandlerService } from '../routes-handler.service';
import { LocationAutocomplete } from '../../classes';
import { CSUserInfo } from '../../models/integration/csUserInfo';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private restService: RestService,
    private fileService: FileService,
    private storageService: StorageService,
    private localization: LocalizationService,
    private notificationService: NotificationService,
    private routesHandler: RoutesHandlerService
  ) {}

  private user: User;
  private user$ = new BehaviorSubject(null);

  currentUser$: Observable<User> = this.user$.asObservable();

  // user api:

  register(user: User): Observable<User> {
    return this.restService.User().register(user);
  }

  login(user: User): Observable<HttpResponse<User>> {
    return this.restService.User().login(user);
  }

  logout(): Observable<null> {
    return this.restService.User().logout();
  }

  refresh(): Observable<HttpResponse<User>> {
    return this.restService.User().refresh();
  }

  getUserList(): Observable<User[]> {
    return this.restService
      .User()
      .getUserList()
      .pipe(
        map((userList: User[]) => {
          const resultUserList = [];

          if (userList?.length) {
            userList.forEach((itemUser: User) => {
              const userItemInstance: User = User.toInstance(itemUser);

              resultUserList.push(userItemInstance);
            });
          }

          return resultUserList;
        })
      );
  }

  addUserPhoto(userPhoto: FormData): Observable<Blob> {
    return this.restService.User().addUserPhoto(userPhoto);
  }

  getUserPhoto(userId: string): Observable<Blob> {
    return this.restService.User().getUserPhoto(userId);
  }

  getHash(): Observable<string> {
    return this.restService
      .User()
      .getHash()
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationService.notify(NotificationTypeEnum.ERROR, error?.error?.message);

          return throwError(error);
        })
      );
  }

  addHash(hash: string, newUser: User): Observable<User> {
    return this.restService.User().addHash(hash, newUser);
  }

  changeName(user: User): Observable<User> {
    return this.restService.User().changeName(user);
  }

  changePassword(user: User): Observable<HttpResponse<User>> {
    return this.restService.User().changePassword(user);
  }

  changeEmail(email: string, user: User): Observable<HttpResponse<User>> {
    return this.restService.User().changeEmail(email, user);
  }

  removeUser(userId: string): Observable<null> {
    return this.restService.User().removeUser(userId);
  }

  changeRole(role: string, userId: string): Observable<User> {
    return this.restService.User().changeRole(role, userId);
  }

  lockUser(isNotLocked: boolean, userId: string): Observable<User> {
    return this.restService.User().lockUser(isNotLocked, userId);
  }

  updateCurrentUser(userId: string): void {
    if (userId) {
      this.restService
        .User()
        .getUser(userId)
        .pipe(take(1))
        .subscribe((currentUser: User) => {
          this.setUserInfoAndUpdate(currentUser);
        });
    }
  }

  updateLanguage(locale: Locale): Observable<null> {
    return this.restService.User().updateLanguage(locale);
  }

  updateOnboardingStatus(status: boolean): Observable<null> {
    return this.restService.User().updateOnboardingStatus(status);
  }

  requestPasswordChange(login: string, lang: Locale): Observable<User> {
    return this.restService.User().requestPasswordChange(login, lang);
  }

  submitPasswordChange(
    key: string,
    lang: Locale,
    newPassword: string
  ): Observable<HttpResponse<User>> {
    return this.restService.User().submitPasswordChange(key, lang, newPassword);
  }

  isTest(): Observable<string> {
    return this.restService.User().isTest();
  }

  getUserGooglePlace(): Observable<GooglePlace> {
    return this.restService.User().getUserGooglePlace();
  }

  saveUtmAndGa(utmSource: string, gaCookie: string): Observable<null> {
    return this.restService.User().saveUtmAndGa(utmSource, gaCookie);
  }

  getApiKey(): Observable<string> {
    return this.restService.User().getApiKey();
  }

  generateNewApiKey(): Observable<string> {
    return this.restService.User().generateNewApiKey();
  }

  removeApiKey(): Observable<string> {
    return this.restService.User().removeApiKey();
  }

  getUserLocationAutocomplete(): Observable<Autocomplete> {
    return this.getUserGooglePlace().pipe(
      map((googlePlace: GooglePlace) => {
        const systemLocale: Locale = this.localization.getSystemLocale();

        return LocationAutocomplete.getLocationAutocompleteResult(googlePlace, systemLocale);
      })
    );
  }

  integrateWithCleverStaff(csUserInfo: CSUserInfo): Observable<User> {
    return this.restService.User().integrateWithCleverStaff(csUserInfo);
  }

  getIsUserAlreadyRegistrated(email: string): Observable<string> {
    return this.restService.User().getIsUserAlreadyRegistrated(email);
  }

  loginCsUser(user: User, csUserInfo: CSUserInfo): Observable<string> {
    return this.restService
      .User()
      .loginCsUser(user, csUserInfo)
      .pipe(map((user: User) => user.redirectUrl));
  }

  registerCsUser(user: User, csUserInfo: CSUserInfo): Observable<User> {
    return this.restService.User().registerCsUser(user, csUserInfo);
  }

  // rest methods:

  setUser(user: User): void {
    this.user = user;
  }

  getUser(): User {
    return this.user;
  }

  getUserPhotoUrl(userId: string): Observable<UserPhotoData> {
    return this.getUserPhoto(userId).pipe(
      filter((item) => !!item),
      map((photoData: Blob) => {
        return photoData ? { photo: photoData, userId } : null;
      }),
      switchMap((userPhotoData: UserPhotoData) => {
        return from(this.fileService.getImageUrlFromBlob(userPhotoData));
      })
    );
  }

  getUserWithPositiveAgreement(): User {
    if (this.user) {
      return User.updateUserWithParameter(this.user, 'pluginAgreement', 'true');
    }

    return null;
  }

  getUserWithPositiveOnboardingStatus(): User {
    if (this.user) {
      return User.updateUserWithParameter(this.user, 'onboardingStatus', 'true');
    }

    return null;
  }

  extractLocaleFromUser(user: User): Locale {
    const langParameter: UserParameter = user?.parameters?.find((paramItem: UserParameter) => {
      return paramItem?.type === 'language';
    });

    return langParameter?.value as Locale;
  }

  extractOnboardingStatusFromUser(user: User = null): boolean {
    if (!user) {
      user = this.user;
    }

    const onboardingStatusParameter: UserParameter = user?.parameters?.find(
      (paramItem: UserParameter) => {
        return paramItem?.type === 'onboardingStatus';
      }
    );

    return onboardingStatusParameter?.value === 'true';
  }

  extractHideFunctionalityFromUser(user: User): boolean {
    const onboardingStatusParameter: UserParameter = user?.parameters?.find(
      (paramItem: UserParameter) => {
        return paramItem?.type === 'hideFunctionality';
      }
    );

    return onboardingStatusParameter?.value === 'true';
  }

  updateUser(user: User): void {
    this.user$.next(User.toInstance(user));
  }

  setUserInfoAndUpdate(user: User): void {
    if (user?.photoId) {
      this.getUserPhotoUrl(user?.userId)
        .pipe(take(1))
        .subscribe(({ photoUrl, userId }: UserPhotoData) => {
          if (photoUrl && userId) {
            user.photoUrl = photoUrl;

            this.updateUser(user);
          }
        });
    } else {
      this.updateUser(user);
    }
  }

  updateUserLocale(user: User = null): void {
    const userLocale: Locale = this.extractLocaleFromUser(user);

    this.localization.setSystemLocale(userLocale);
  }

  updateUserRole(role: RoleType): void {
    if (this.user && role) {
      this.user.role = role;

      this.updateUser(this.user);
    }
  }

  requestPasswordChangeWithLocalization(login: string): Observable<User> {
    const lang: Locale = this.localization.getSystemLocale();

    return this.requestPasswordChange(login, lang);
  }

  submitPasswordChangeWithLocalization(
    key: string,
    password: string
  ): Observable<HttpResponse<User>> {
    if (password) {
      const lang: Locale = this.localization.getSystemLocale();

      return this.submitPasswordChange(key, lang, password);
    }

    return of(null);
  }

  requestPasswordChangeSuccess(user: User = null): void {
    if (user?.email) {
      this.notificationService.notify(
        NotificationTypeEnum.SUCCESS,
        'NOTIFICATION.CHANGE_PASSWORD_LETTER_INFO',
        { email: user.email }
      );
    }
  }
}
