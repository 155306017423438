<div class="candidate-main-info">
  <section class="cv-section">
    <div
      *ngIf="mainSkillList?.length"
      class="skills-block no-splittable-block"
      data-base="30"
      data-min="42"
      data-line="12"
      data-gap="9">
      <h4 class="section-title">Skills</h4>

      <div class="skills-block__container">
        <span
          *ngFor="let mainSkill of mainSkillList"
          class="skills-block__item bolder ">
          {{ mainSkill }}
        </span>
      </div>
    </div>

    <div
      *ngIf="secondarySkillList?.length"
      class="skills-block no-splittable-block"
      data-base="20"
      data-min="32"
      data-line="12"
      data-gap="9">
      <h4 class="section-sm-title">Other Skills</h4>

      <div class="skills-block__container">
        <span
          *ngFor="let secondarySkill of secondarySkillList"
          class="skills-block__item bolder">
          {{ secondarySkill }}
        </span>
      </div>
    </div>

    <div *ngIf="profile?.languages?.length" class="skills-block no-splittable-block">
      <h4 class="section-sm-title">Languages</h4>

      <div class="skills-block__container">
        <span
          *ngFor="let language of profile.languages"
          class="skills-block__item">
          <span class="bolder">
            {{ language.language | emojiCleaner }}
          </span>

          <ng-container *ngIf="language.level">
            ({{ 'LEVELS.' + language.level | translate }})
          </ng-container>
        </span>
      </div>
    </div>
  </section>

  <section *ngIf="profile?.positions" class="cv-section">
    <h4 class="section-title no-splittable-block">Work Experience</h4>

    <div
      *ngFor="let position of profile.positions"
      class="experience-block no-splittable-block">
      <div class="experience-block__header">
        <div class="experience-block__row">
          <span class="position">
            {{ position.positionTitle | emojiCleaner }}
          </span>

          <app-time
            class="experience-duration"
            [duration]="position.duration"
            [extraClass]="'small'">
          </app-time>
        </div>

        <div class="experience-block__row">
          <span class="company-name">
            {{ position.companyName | emojiCleaner }}

            <ng-container *ngIf="position.location">
              — {{ position.location | location }}
            </ng-container>
          </span>

          <span class="experience-period">
            {{ position.duration | durationPeriod : 'full' : 'en' }}
          </span>
        </div>
      </div>

      <div class="experience-block__content">
        {{ position.description | emojiCleaner }}
      </div>
    </div>
  </section>

  <section *ngIf="profile?.educations" class="cv-section">
    <h4 class="section-title no-splittable-block">Education</h4>

    <div
      *ngFor="let education of profile.educations"
      class="experience-block no-splittable-block">
      <div class="experience-block__header">
        <div class="experience-block__row">
          <span class="position">
            {{ education.degreeName | emojiCleaner }}
          </span>

          <app-time
            class="experience-duration"
            [duration]="education.duration"
            [extraClass]="'small'">
          </app-time>
        </div>

        <div class="experience-block__row">
          <span class="company-name">
            {{ education.schoolName | emojiCleaner }}
          </span>

          <span class="experience-period">
            {{ education.duration | durationPeriod : 'full' }}
          </span>
        </div>
      </div>

      <div class="experience-block__content">
        {{ education.fieldOfStudy | emojiCleaner }}
      </div>
    </div>
  </section>

  <section *ngIf="profile?.certifications" class="cv-section">
    <h4 class="section-title no-splittable-block">Courses</h4>

    <div
      *ngFor="let certificate of profile.certifications"
      class="experience-block no-splittable-block">
      <div class="experience-block__header">
        <div class="experience-block__row">
          <span class="position">
            {{ certificate.certName | emojiCleaner }}
          </span>

          <app-time
            class="experience-duration"
            [duration]="certificate.duration"
            [extraClass]="'small'">
          </app-time>
        </div>

        <div class="experience-block__row">
          <span class="company-name">
            {{ certificate.authority | emojiCleaner }}
          </span>

          <span class="experience-period">
            {{ certificate.duration | durationPeriod : 'full' }}
          </span>
        </div>
      </div>
    </div>
  </section>
</div>
