import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalInfo } from 'src/app/shared/models';

@Component({
  selector: 'app-integration-success-modal',
  templateUrl: './integration-success-modal.component.html',
  styleUrls: ['./integration-success-modal.component.scss']
})
export class IntegrationSuccessModalComponent {
  constructor(public dialogRef: MatDialogRef<IntegrationSuccessModalComponent>) {}

  info: ModalInfo = {
    type: 'simple-modal',
    closeModalBtnId: 'close-integration-success-m',
    header: {}
  };

  closeModal(): void {
    this.dialogRef.close();
  }
}
