<app-modal
[info]="info"
(closeModal)="closeModal()">
  <div class="integration-success">
    <div class="integration-success__content">
      <div class="animation">
        <app-waiting-animation [state]="'success'"></app-waiting-animation>
      </div>
    
      <div class="info-text">
        <p>{{'INTEGRATION_SETTINGS.SUCCESS_MODAL_CLEVER_STUFF.TITLE' | translate}}</p>
      </div>
    </div>
    
    <div class="integration-success__text">
      <p>{{'INTEGRATION_SETTINGS.SUCCESS_MODAL_CLEVER_STUFF.INTEGRATION_INFO' | translate}}</p>
    </div>
    
  </div>
</app-modal>